$color-a: #2d587f;
$color-b: #2d587f;
$color-b1: #2d587f;
$color-b2: #2d587f;
$color-c: #333;
@import 'common/common';


$color-d: $white;
$color-e: #f6f9fd url('../themebg/background_gradient.png') repeat-x 0 51px;
$color-f: $color-d;

// Navigator-Colors

// Navigator-Border
$color-n: $color-b;
// Navigator BackgroundColor
$color-o: $color-b;
// Label-color
$color-r: $color-d;
// Icon-color
$color-t: $color-d;
// Active Label-color
$color-s: $color-c;
// Active Icon-color
$color-u: $color-b;
// Logo-Color
$color-v: $color-d;
// @deprecated
$color-l: transparent;

$shadow: rgba($color-c-d10, 0.4);

// Logo Title
$logo-title-color: $color-d;

// Link
$color-link: $color-b;
